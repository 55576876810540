
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PortalToolTip',
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: './questionMark.svg',
    },
    backgroundColor: {
      type: String,
      default: 'var(--color-grey40)',
    },
    description: {
      type: String,
      default: '',
    },
    ariaId: {
      type: String,
      default: '',
    },
  },
});
