
import { defineComponent } from 'vue';

import ModalDialog from '@/components/ModalDialog.vue';
import Translate from '@/i18n/Translate.vue';

export default defineComponent({
  name: 'CategoryAddModal',
  components: {
    ModalDialog,
    Translate,
  },
  methods: {
    openModal(action): void {
      if (action === 'createCategory') {
        this.$store.dispatch('modal/setAndShowModal', {
          name: 'AdminCategory',
          props: {
            modelValue: {},
            label: 'ADD_CATEGORY',
          },
        });
      }
      if (action === 'addCategory') {
        this.$store.dispatch('modal/setAndShowModal', {
          name: 'AdminExistingCategory',
        });
      }
    },
    cancel() {
      this.$store.dispatch('modal/hideAndClearModal');
    },
  },
});
