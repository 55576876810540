
import { defineComponent } from 'vue';

import ModalDialog from '@/components/ModalDialog.vue';
import Translate from '@/i18n/Translate.vue';

export default defineComponent({
  name: 'TileAddModal',
  components: {
    ModalDialog,
    Translate,
  },
  props: {
    superDn: {
      type: String,
      required: true,
    },
    forFolder: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    openModal(action): void {
      if (action === 'createEntry') {
        this.$store.dispatch('modal/setAndShowModal', {
          name: 'AdminEntry',
          props: {
            modelValue: {},
            superDn: this.superDn,
            label: 'NEW_ENTRY',
            fromFolder: this.forFolder,
          },
        });
      }
      if (action === 'addEntry') {
        let superObjectGetter = 'portalData/portalCategories';
        if (this.forFolder) {
          superObjectGetter = 'portalData/portalFolders';
        }
        this.$store.dispatch('modal/setAndShowModal', {
          name: 'AdminExistingEntry',
          props: {
            label: 'ADD_EXISTING_ENTRY',
            objectGetter: 'portalData/portalEntries',
            superObjectGetter,
            superDn: this.superDn,
          },
        });
      }
      if (action === 'createFolder') {
        this.$store.dispatch('modal/setAndShowModal', {
          name: 'AdminFolder',
          props: {
            modelValue: {},
            superDn: this.superDn,
            label: 'NEW_FOLDER',
          },
        });
      }
      if (action === 'addFolder') {
        this.$store.dispatch('modal/setAndShowModal', {
          name: 'AdminExistingEntry',
          props: {
            label: 'ADD_EXISTING_FOLDER',
            objectGetter: 'portalData/portalFolders',
            superObjectGetter: 'portalData/portalCategories',
            superDn: this.superDn,
          },
        });
      }
    },
    cancel() {
      this.$store.dispatch('modal/hideAndClearModal');
    },
  },
});
