
import { defineComponent } from 'vue';

import PortalIcon from '@/components/globals/PortalIcon.vue';

import Translate from '@/i18n/Translate.vue';

export default defineComponent({
  name: 'PortalButton',
  components: {
    PortalIcon,
    Translate,
  },
  props: {
    buttonLabel: {
      type: String,
      default: 'SUBMIT',
    },
    iconBefore: {
      type: String,
      default: '',
    },
    iconBeforeClass: {
      type: String,
      default: '',
    },
    iconAfter: {
      type: String,
      default: '',
    },
    iconAfterClass: {
      type: String,
      default: '',
    },
  },
  methods: {},
});
