
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import MenuItem from '@/components/navigation/MenuItem.vue';
import PortalIcon from '@/components/globals/PortalIcon.vue';

import { login, logout } from '@/jsHelper/login';
import Translate from '@/i18n/Translate.vue';

interface SideNavigationData {
  menuVisible: boolean,
  subMenuVisible: boolean,
  subMenuClass: string,
  menuParent: number,
  init: boolean,
  fade: boolean,
  fadeRightLeft: string,
  fadeLeftRight: string,
  changeLanguageTranslation: unknown
}

export default defineComponent({
  name: 'SideNavigation',
  components: {
    PortalIcon,
    MenuItem,
    Translate,
  },
  data(): SideNavigationData {
    return {
      menuVisible: true,
      subMenuVisible: false,
      subMenuClass: 'portal-sidenavigation__menu-item--hide',
      menuParent: -1,
      init: true,
      fade: false,
      fadeRightLeft: 'portal-sidenavigation__fade-right-left',
      fadeLeftRight: 'portal-sidenavigation__fade-left-right',
      // TODO: outsource translation
      changeLanguageTranslation: {
        de_DE: 'Sprache ändern',
        en_US: 'Change language',
      },
    };
  },
  computed: {
    ...mapGetters({
      menuLinks: 'menu/getMenu',
      editMode: 'portalData/editMode',
      userState: 'user/userState',
      meta: 'metaData/getMeta',
    }),
  },
  mounted() {
    this.focusOnLoginButton();
  },
  methods: {
    login(): void {
      login(this.userState);
    },
    logout(): void {
      logout();
    },
    closeNavigation(): void {
      this.$store.dispatch('navigation/setActiveButton', '');
      document.getElementById('header-button-menu')?.focus();
    },
    toggleMenu(index = -1): void {
      this.menuVisible = !this.menuVisible;
      this.menuParent = index;
      this.subMenuVisible = !this.subMenuVisible;
      this.fade = !this.fade;
      this.init = false;

      if (this.subMenuVisible) {
        this.subMenuClass = 'portal-sidenavigation__menu-item--show';
      } else {
        this.subMenuClass = 'portal-sidenavigation__menu-item--hide';
      }
    },
    async startEditMode(): Promise<void> {
      await this.$store.dispatch('portalData/setEditMode', true);
      this.$store.dispatch('navigation/setActiveButton', 'settings');
    },
    setFadeClass(): string {
      let ret = '';
      if (!this.init) {
        if (!this.fade) {
          ret = this.fadeLeftRight;
        } else {
          ret = this.fadeRightLeft;
        }
      }
      return ret;
    },
    selectPrevious(menuReference: string, index?: number, numberOfItems?: number): void {
      // test
      if (menuReference === 'subItemParent') {
        // If current is subitem Parent focus last item in list
        this.$nextTick(() => {
          const lastChildIndex = numberOfItems ? numberOfItems - 1 : null;
          const firstSubItemChild = (this.$refs[`subItem${lastChildIndex}`] as HTMLFormElement).$el;
          firstSubItemChild.focus();
        });
      } else if (menuReference === 'subItem' || menuReference === 'menuItem') {
        if (index === 0) {
          // If current is first submenu item set focus to subItemParent.
          if (menuReference === 'subItem') {
            this.focusOnSubItemParent();
          } else {
            const lastElementIndex = numberOfItems ? numberOfItems - 1 : null;
            (this.$refs[`menuItem${lastElementIndex}`] as HTMLFormElement).$el.focus();
          }
        } else {
          // normal previous behaviour
          const currentElement = (this.$refs[menuReference + index] as HTMLFormElement).$el;
          const previousElement = currentElement.parentElement.previousElementSibling.children[0];
          previousElement.focus();
        }
      }
    },
    selectNext(menuReference: string, index?: number, numberOfItems?: number): void {
      if (menuReference === 'subItemParent') {
        this.$nextTick(() => {
          const firstSubItemChild = (this.$refs.subItem0 as HTMLFormElement).$el;
          firstSubItemChild.focus();
        });
      } else {
        const currentElement = (this.$refs[menuReference + index] as HTMLFormElement).$el;
        const lastChildIndex = numberOfItems ? numberOfItems - 1 : null;
        if (index === lastChildIndex) {
          if (menuReference === 'subItem') {
            this.focusOnSubItemParent();
          } else {
            (this.$refs.menuItem0 as HTMLFormElement).$el.focus();
          }
        } else {
          const nextElement = currentElement.parentElement.nextElementSibling.children[0];
          nextElement.focus();
        }
      }
    },
    hasSubmenu(item): boolean {
      return item.subMenu && item.subMenu.length > 0;
    },
    focusOnChild(index): void {
      this.toggleMenu(index);
      this.$nextTick(() => {
        this.focusOnSubItemParent();
      });
    },
    focusOnParent(index): void {
      this.toggleMenu(index);
      this.$nextTick(() => {
        const parentMenuItem = (this.$refs[`menuItem${index}`] as HTMLFormElement).$el;
        parentMenuItem.focus();
      });
    },
    focusOnSubItemParent(): void {
      (this.$refs.subItemParent as HTMLFormElement).$el.focus();
    },
    focusOnLastItemInSideMenu(event): void {
      // call this to trap user in side navigation
      event.preventDefault();
      if (this.userState.mayEditPortal) {
        this.focusOnEditModeButton();
      } else {
        const lastElementIndex = this.menuLinks.length - 1;
        const lastMenuItem = (this.$refs[`menuItem${lastElementIndex}`] as HTMLFormElement) ? (this.$refs[`menuItem${lastElementIndex}`] as HTMLFormElement).$el : null;
        if (lastMenuItem) {
          // focus to last element of menu
          lastMenuItem.focus();
        } else {
          // if submenu is open the focus should go on last submenuitem
          const subMenuItems = Object.keys(this.$refs).filter((refTitle) => refTitle.includes('subItem'));
          const lastSubMenuItemReference = subMenuItems[subMenuItems.length - 1];
          (this.$refs[lastSubMenuItemReference] as HTMLFormElement).$el.focus();
        }
      }
    },
    focusOnLoginButton(): void {
      (this.$refs.loginButton as HTMLFormElement).focus();
    },
    focusOnEditModeButton(): void {
      (this.$refs.editModeButton as HTMLFormElement).focus();
    },
    nextElementTab(event, index?: number): void {
      // only call this on last Menu Item to trap user in side navigation
      const lastElementIndex = this.menuLinks.length - 1;
      if (index === lastElementIndex) {
        event.preventDefault();
        if (this.userState.mayEditPortal) {
          this.focusOnEditModeButton();
        } else {
          this.focusOnLoginButton();
        }
      }
    },
  },
});
