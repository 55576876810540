
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import PortalButton from '@/components/globals/PortalButton.vue';

import { catalog } from '@/i18n/translations';
import { setCookie, getCookie } from '@/jsHelper/cookieHelper';

interface CookieBannerData {
  fadeOutClass: string,
}

export default defineComponent({
  name: 'CookieBanner',
  components: { PortalButton },
  data(): CookieBannerData {
    return { fadeOutClass: '' };
  },
  computed: {
    ...mapGetters({ metaData: 'metaData/getMeta' }),
    cookieName(): string {
      return this.metaData.cookieBanner.cookie || 'univentionCookieSettingsAccepted';
    },
    defaultCookieTitle(): string {
      return catalog.COOKIE_TITLE.translated.value;
    },
    defaultCookieText(): string {
      return catalog.COOKIE_TEXT.translated.value;
    },
    showCookieBanner(): boolean {
      return this.metaData.cookieBanner.show && getCookie(this.cookieName) === '';
    },
  },
  methods: {
    setCookies(): void {
      const cookieValue = 'do-not-change-me';
      setCookie(this.cookieName, cookieValue);
      this.dismissCookieBanner();
    },
    dismissCookieBanner(): void {
      this.fadeOutClass = 'cookie-banner__fade-out';
    },
  },
});
