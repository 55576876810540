
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import HeaderButton from '@/components/navigation/HeaderButton.vue';
import HeaderTab from '@/components/navigation/HeaderTab.vue';
import NotificationBubble from '@/components/globals/NotificationBubble.vue';
import NotificationBubbleSlot from '@/components/globals/NotificationBubbleSlot.vue';
import PortalSearch from '@/components/search/PortalSearch.vue';
import ChooseTabs from '@/components/ChooseTabs.vue';
import notificationMixin from '@/mixins/notificationMixin.vue';

interface PortalHeaderData {
  tabsOverflow: boolean;
}

export default defineComponent({
  name: 'PortalHeader',
  components: {
    HeaderButton,
    HeaderTab,
    NotificationBubble,
    NotificationBubbleSlot,
    PortalSearch,
    ChooseTabs,
  },
  mixins: [
    notificationMixin,
  ],
  data(): PortalHeaderData {
    return {
      tabsOverflow: false,
    };
  },
  computed: {
    ...mapGetters({
      portalLogo: 'portalData/portalLogo',
      portalName: 'portalData/portalName',
      activeTabIndex: 'tabs/activeTabIndex',
      tabs: 'tabs/allTabs',
      editMode: 'portalData/editMode',
      activeButton: 'navigation/getActiveButton',
    }),
    ariaLabelPortalHeader(): string {
      return `${this.$translateLabel('SHOW_PORTAL')}`;
    },
    ariaLabelStartEditMode(): string {
      return `${this.$translateLabel('OPEN_EDIT_SIDEBAR')}`;
    },
    ariaLabelStopEditmode(): string {
      return `${this.$translateLabel('STOP_EDIT_PORTAL')}`;
    },
    ariaLabelEditmode(): string {
      return `${this.$translateLabel('EDIT_MODE')}`;
    },
    ariaLabelTabs(): string {
      return `${this.$translateLabel('TABS')}`;
    },
    ariaLabelSearch(): string {
      return `${this.$translateLabel('SEARCH')}`;
    },
    ariaLabelNotifications(): string {
      return `${this.$translateLabel('NOTIFICATIONS')}`;
    },
    ariaLabelMenu(): string {
      return `${this.$translateLabel('MENU')}`;
    },
    showTabButton(): boolean {
      return this.tabs.length > 0;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.updateOverflow);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateOverflow);
  },
  updated(): void {
    this.updateOverflow();
  },
  methods: {
    updateOverflow() {
      const tabs = this.$refs.tabs as HTMLElement;
      if (tabs === null) {
        return;
      }
      this.tabsOverflow = tabs.scrollWidth > tabs.clientWidth;
    },
    chooseTab(): void {
      this.$store.dispatch('modal/setAndShowModal', {
        name: 'ChooseTabs',
      });
    },
    goHome(): void {
      this.$store.dispatch('tabs/setActiveTab', 0);
    },
    stopEditMode(): void {
      this.$store.dispatch('portalData/setEditMode', false);
      this.$store.dispatch('navigation/setActiveButton', '');
    },
  },
});
