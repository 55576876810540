
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

import IconButton from '@/components/globals/IconButton.vue';

import TileClick from '@/mixins/TileClick.vue';
import Draggable from '@/mixins/Draggable.vue';

import { Title, Description } from '@/store/modules/portalData/portalData.models';

export default defineComponent({
  name: 'PortalTile',
  components: {
    IconButton,
  },
  mixins: [
    TileClick,
    Draggable,
  ],
  props: {
    dn: {
      type: String,
      required: true,
    },
    superDn: {
      type: String,
      required: true,
    },
    title: {
      type: Object as PropType<Title>,
      required: true,
    },
    description: {
      type: Object as PropType<Description>,
      required: true,
    },
    activated: {
      type: Boolean,
      required: false,
    },
    pathToLogo: {
      type: String,
      required: false,
      default: './questionMark.svg',
    },
    backgroundColor: {
      type: String,
      default: 'var(--color-grey40)',
    },
    minified: {
      type: Boolean,
      default: false,
    },
    hasFocus: {
      type: Boolean,
      default: false,
    },
    lastElement: {
      type: Boolean,
      default: false,
    },
    firstElement: {
      type: Boolean,
      default: false,
    },
    fromFolder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['keepFocusInFolderModal'],
  computed: {
    ...mapGetters({
      tooltip: 'tooltip/tooltip',
    }),
    wrapperTag(): string {
      return (this.minified || this.editMode) ? 'div' : 'a';
    },
    isTouchDevice(): boolean {
      return 'ontouchstart' in document.documentElement;
    },
    ariaLabelPortalTile(): null | string {
      return (this.minified || this.editMode) ? null : this.$localized(this.title);
    },
  },
  mounted() {
    if (this.hasFocus) {
      this.$el.children[0].focus(); // sets focus to first Element in opened Folder
    }
  },
  methods: {
    hideTooltip(): void {
      this.$store.dispatch('tooltip/unsetTooltip');
    },
    showTooltip(): void {
      if (!this.minified) {
        const tooltip = {
          title: this.$localized(this.title),
          backgroundColor: this.backgroundColor,
          icon: this.pathToLogo,
          description: this.$localized(this.description),
          ariaId: this.createID(),
        };
        this.$store.dispatch('tooltip/setTooltip', { tooltip });
      }
    },
    setFocus(event, direction): void {
      if (this.lastElement && direction === 'forward') {
        event.preventDefault();
        this.$emit('keepFocusInFolderModal', 'focusFirst');
      } else if (this.firstElement && direction === 'backward') {
        event.preventDefault();
        this.$emit('keepFocusInFolderModal', 'focusLast');
      }
    },
    editTile() {
      this.$store.dispatch('modal/setAndShowModal', {
        name: 'AdminEntry',
        props: {
          modelValue: this.$props,
          superDn: this.superDn,
          fromFolder: this.fromFolder,
          label: 'EDIT_ENTRY',
        },
      });
    },
    toolTipTouchHandler() {
      if (this.tooltip) {
        this.hideTooltip();
      } else {
        this.showTooltip();
      }
    },
    createID() {
      return `element-${this.$.uid}`;
    },
  },
});
