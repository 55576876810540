
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

import TileAdd from '@/components/admin/TileAdd.vue';
import IconButton from '@/components/globals/IconButton.vue';
import PortalFolder from '@/components/PortalFolder.vue';
import PortalTile from '@/components/PortalTile.vue';
import Draggable from '@/mixins/Draggable.vue';
import {
  Title,
  Tile,
  FolderTile,
  Description,
  BaseTile,
} from '@/store/modules/portalData/portalData.models';

interface PortalCategoryData {
  showCategoryHeadline: boolean,
}

export default defineComponent({
  name: 'PortalCategory',
  components: {
    TileAdd,
    PortalTile,
    PortalFolder,
    IconButton,
  },
  mixins: [
    Draggable,
  ],
  props: {
    dn: {
      type: String,
      required: true,
    },
    title: {
      type: Object as PropType<Title>,
      required: true,
    },
    tiles: {
      type: Array as PropType<Tile[]>,
      required: true,
    },
  },
  data(): PortalCategoryData {
    return {
      showCategoryHeadline: false,
    };
  },
  computed: {
    ...mapGetters({
      editMode: 'portalData/editMode',
      searchQuery: 'search/searchQuery',
      dragDropIds: 'dragndrop/getId',
    }),
    hasTiles(): boolean {
      return this.tiles.some((tile) => this.tileMatchesQuery(tile));
    },
  },
  methods: {
    async tileDropped(evt: DragEvent) {
      evt.preventDefault();
      if (evt.dataTransfer === null) {
        return;
      }
      const data = this.dragDropIds;
      if (this.dn === data.superDn) {
        this.$store.dispatch('dragndrop/dropped');
        this.$store.dispatch('activateLoadingState');
        await this.$store.dispatch('portalData/saveContent');
        this.$store.dispatch('deactivateLoadingState');
      }
    },
    async categoryDropped(evt: DragEvent) {
      evt.preventDefault();
      if (evt.dataTransfer === null) {
        return;
      }
      const data = this.dragDropIds;
      if (!data.superDn) {
        this.$store.dispatch('dragndrop/dropped');
        this.$store.dispatch('activateLoadingState');
        await this.$store.dispatch('portalData/savePortalCategories');
        this.$store.dispatch('deactivateLoadingState');
      }
    },
    editCategory() {
      this.$store.dispatch('modal/setAndShowModal', {
        name: 'AdminCategory',
        props: {
          modelValue: this.$props,
          label: 'EDIT_CATEGORY',
        },
      });
    },
    titleMatchesQuery(title: Title): boolean {
      return this.$localized(title).toLowerCase()
        .includes(this.searchQuery.toLowerCase());
    },
    descriptionMatchesQuery(description: Description): boolean {
      return this.$localized(description).toLowerCase()
        .includes(this.searchQuery.toLowerCase());
    },
    tileMatchesQuery(tile: Tile): boolean {
      const titleMatch = this.titleMatchesQuery(tile.title);
      const descriptionMatch = (tile as BaseTile).description ? this.descriptionMatchesQuery((tile as BaseTile).description as Description) : false;
      const folderMatch = tile.isFolder && (tile as FolderTile).tiles.some((t) => this.titleMatchesQuery(t.title));
      return titleMatch || folderMatch || descriptionMatch;
    },
  },
});
