
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

import PortalTile from '@/components/PortalTile.vue';
import Draggable from '@/mixins/Draggable.vue';
import IconButton from '@/components/globals/IconButton.vue';
import TileAdd from '@/components/admin/TileAdd.vue';
import { Title, Tile } from '@/store/modules/portalData/portalData.models';

export default defineComponent({
  name: 'PortalFolder',
  components: {
    PortalTile,
    IconButton,
    TileAdd,
  },
  mixins: [
    Draggable,
  ],
  props: {
    dn: {
      type: String,
      required: true,
    },
    title: {
      type: Object as PropType<Title>,
      required: true,
    },
    tiles: {
      type: Array as PropType<Tile[]>,
      required: true,
    },
    superDn: {
      type: String,
      required: true,
    },
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({ editMode: 'portalData/editMode' }),
    hasTiles(): boolean {
      return this.tiles.length > 0;
    },
    ariaLabelFolder(): string {
      return `${this.$translateLabel('FOLDER')}`;
    },
  },
  methods: {
    closeFolder(): void {
      this.$store.dispatch('modal/hideAndClearModal');
      this.$store.dispatch('tooltip/unsetTooltip');
    },
    openFolder() {
      if (this.inModal) {
        return;
      }
      this.$store.dispatch('modal/setAndShowModal', {
        name: 'PortalFolder',
        props: { ...this.$props, inModal: true },
      });
    },
    setFocus(index): boolean {
      return this.inModal && index === 0;
    },
    isLastElement(index, array): boolean {
      return index === (array.length - 1);
    },
    isFirstElement(index): boolean {
      return index === 0;
    },
    keepFocusInFolderModal(focusElement) {
      // TODO: Following $refs are bad practice and do not have proper typescript support
      const firstElement = (this.$refs.portalFolderChildren0 as HTMLFormElement).$el.children[0];
      const lastChild = `portalFolderChildren${this.tiles.length - 1}`;
      const lastElement = (this.$refs[lastChild] as HTMLFormElement).$el.children[0];

      if (focusElement === 'focusLast') {
        lastElement.focus();
      } else if (focusElement === 'focusFirst') {
        firstElement.focus();
      }
    },
    editFolder() {
      this.$store.dispatch('modal/setAndShowModal', {
        name: 'AdminFolder',
        props: {
          modelValue: this.$props,
          superDn: this.superDn,
          label: 'EDIT_FOLDER',
        },
      });
    },
  },
});
