
import { defineComponent } from 'vue';

import CookieBanner from '@/components/globals/CookieBanner.vue';
import Portal from '@/views/Portal.vue';

import addCustomStyles from '@/jsHelper/addCustomStyles';
import { getCookie } from '@/jsHelper/tools';
import { login } from '@/jsHelper/login';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    CookieBanner,
    Portal,
  },
  computed: {
    ...mapGetters({
      bubbleContent: 'notificationBubble/bubbleContent',
      userState: 'user/userState',
    }),
  },
  async mounted() {
    // Set locale and load portal data from backend
    const umcLang = getCookie('UMCLang');
    if (umcLang) {
      await this.$store.dispatch('locale/setLocale', umcLang.replace('-', '_'));
    }
    this.$store.dispatch('activateLoadingState');
    const answer = await this.$store.dispatch('loadPortal', {
      adminMode: false,
    });
    if (answer.portal && answer.portal.ensureLogin && !this.userState.username) {
      login(this.userState);
    }
    addCustomStyles();

    this.$store.dispatch('deactivateLoadingState');
  },
});
