
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import FlyoutWrapper from '@/components/navigation/FlyoutWrapper.vue';

interface PortalSearchData {
  portalSearch: string,
}

export default defineComponent({
  name: 'PortalSearch',
  components: { FlyoutWrapper },
  data(): PortalSearchData {
    return { portalSearch: '' };
  },
  computed: {
    ...mapGetters({
      activeButton: 'navigation/getActiveButton',
      modalState: 'modal/modalState',
      searchQuery: 'search/searchQuery',
      emptySearchResults: 'search/emptySearchResults',
    }),
  },
  mounted() {
    this.$nextTick(() => {
      (this.$refs.portalSearchInput as HTMLElement).focus();
    });
  },
  beforeUnmount() {
    this.$store.dispatch('search/setSearchQuery', '');
  },
  methods: {
    searchTiles(): void {
      this.$store.dispatch('search/setSearchQuery', this.portalSearch.toLowerCase());
      this.$nextTick(() => {
        if (document.querySelectorAll('.portal-tile').length === 0) {
          this.$store.dispatch('search/setSearchResultsEmpty');
        } else {
          this.$store.dispatch('search/setSearchResultsNotEmpty');
        }
      });
    },
    closeSearchInput(): void {
      this.$store.dispatch('navigation/setActiveButton', '');
    },
  },
});
