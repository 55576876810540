
import { defineComponent } from 'vue';

import PortalIcon from '@/components/globals/PortalIcon.vue';
import Translate from '@/i18n/Translate.vue';

export default defineComponent({
  name: 'ImageUpload',
  components: {
    PortalIcon,
    Translate,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    drop(evt: DragEvent) {
      const dt = evt.dataTransfer;
      if (dt && dt.files) {
        this.handleFiles(dt.files);
      }
    },
    startUpload() {
      (this.$refs.file_input as HTMLElement).click();
    },
    upload(evt: Event) {
      const target = evt.target as HTMLInputElement;
      if (target.files) {
        this.handleFiles(target.files);
      }
    },
    handleFiles(files) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          console.log('e.target: ', e.target);
          this.$emit('update:modelValue', e.target.result);
        }
      };
      reader.readAsDataURL(file);
    },
    remove() {
      this.$emit('update:modelValue', '');
    },
  },
});
