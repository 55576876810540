
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { put, add } from '@/jsHelper/admin';
import LocaleInput from '@/components/widgets/LocaleInput.vue';
import EditWidget, { ValidatableData } from '@/components/admin/EditWidget.vue';
import Translate from '@/i18n/Translate.vue';

interface AdminCategoryData extends ValidatableData {
  name: string,
  title: Record<string, string>,
}

function getErrors(this: AdminCategoryData) {
  const errors: Record<string, string> = {};
  if (!this.name) {
    errors.name = 'ERROR_ENTER_NAME';
  }
  if (!this.title.en_US) {
    errors.title = 'ERROR_ENTER_TITLE';
  }
  return errors;
}

export default defineComponent({
  name: 'FormCategoryEdit',
  components: {
    EditWidget,
    Translate,
    LocaleInput,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data(): AdminCategoryData {
    return {
      name: '',
      title: {},
      getErrors,
    };
  },
  computed: {
    ...mapGetters({
      portalDn: 'portalData/getPortalDn',
      categories: 'portalData/portalCategoriesOnPortal',
    }),
  },
  created(): void {
    const dn = this.modelValue.dn;
    if (dn) {
      this.name = dn.slice(3, dn.indexOf(','));
    }
    this.title = { ...(this.modelValue.title || {}) };
  },
  methods: {
    cancel() {
      this.$store.dispatch('modal/hideAndClearModal');
    },
    async remove() {
      this.$store.dispatch('activateLoadingState');
      const dn = this.modelValue.dn;
      const portalAttrs = {
        categories: this.categories.filter((catDn) => catDn !== dn),
      };
      console.info('Removing', dn, 'from', this.portalDn);
      put(this.portalDn, portalAttrs, this.$store, 'CATEGORY_REMOVED_SUCCESS', 'CATEGORY_REMOVED_FAILURE');
      this.$store.dispatch('deactivateLoadingState');
    },
    async finish() {
      this.$store.dispatch('activateLoadingState');
      const attrs = {
        name: this.name,
        displayName: Object.entries(this.title),
      };
      if (this.modelValue.dn) {
        console.info('Modifying', this.modelValue.dn);
        put(this.modelValue.dn, attrs, this.$store, 'CATEGORY_MODIFIED_SUCCESS', 'CATEGORY_MODIFIED_FAILURE');
      } else {
        console.info('Adding category');
        console.info('Then adding it to', this.categories, 'of', this.portalDn); // Okay, strange. message needs to be here, otherwise "this" seems to forget its props!
        const dn = await add('portals/category', attrs, this.$store, 'CATEGORY_ADDED_FAILURE');
        if (dn) {
          console.info(dn, 'added');
          const portalAttrs = {
            categories: this.categories.concat([dn]),
          };
          await put(this.portalDn, portalAttrs, this.$store, 'CATEGORY_ADDED_SUCCESS', 'CATEGORY_ADDED_FAILURE');
        }
      }
      this.$store.dispatch('deactivateLoadingState');
    },
  },
});
