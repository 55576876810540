
import { defineComponent } from 'vue';

import HeaderButton from '@/components/navigation/HeaderButton.vue';

export default defineComponent({
  name: 'HeaderTab',
  components: { HeaderButton },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
    tabLabel: {
      type: String,
      default: 'Nav Tab',
    },
    closeIcon: {
      type: String,
      default: 'x',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMounted: false,
      hasFocus: false,
    };
  },
  computed: {
    ariaLabelClose(): string {
      return `${this.tabLabel}:  ${this.$translateLabel('CLOSE_TAB')}`;
    },
    ariaLabelFocus(): string {
      return `${this.tabLabel}:  ${this.$translateLabel('SELECT_TAB')}`;
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    focusTab(): void {
      this.$store.dispatch('tabs/setActiveTab', this.tabIndex);
    },
    closeTab(): void {
      this.$store.dispatch('tabs/deleteTab', this.tabIndex);
    },
    setFocusStyleToParent():void {
      this.hasFocus = true;
    },
    removeFocusStyleFromParent():void {
      this.hasFocus = false;
    },
    reManageFocus():void {
      this.closeTab();
    },
  },
});
