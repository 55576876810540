
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

import IconButton from '@/components/globals/IconButton.vue';
import PortalIcon from '@/components/globals/PortalIcon.vue';
import Translate from '@/i18n/Translate.vue';

interface LinkWidgetData {
  modelValueData: Array<unknown>,
}

interface LocaleAndValue {
  locale: string,
  value: string,
}

export default defineComponent({
  name: 'LinkWidget',
  components: {
    IconButton,
    PortalIcon,
    Translate,
  },
  props: {
    modelValue: {
      type: Array as PropType<LocaleAndValue[]>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data(): LinkWidgetData {
    return {
      modelValueData: [],
    };
  },
  computed: {
    ...mapGetters({
      locales: 'locale/getAvailableLocales',
      currentLocale: 'locale/getLocale',
    }),
  },
  created() {
    this.modelValue.forEach((val) => {
      this.modelValueData.push({
        locale: val.locale,
        value: val.value,
      });
    });
    this.modelValueData.push({
      locale: 'en_US',
      value: '',
    });
  },
  updated() {
    this.$emit('update:modelValue', this.modelValueData);
  },
  methods: {
    addField() {
      this.modelValueData.push({ locale: this.currentLocale || 'en_US', value: '' });
      const i = (this.modelValueData.length - 1);

      setTimeout(() => {
        const elem = (this.$refs[`link${i}`] as HTMLElement);
        elem.focus();
      }, 50);
    },
    removeField(index) {
      this.modelValueData.splice(index, 1);
    },
  },
});

export { LocaleAndValue };

