
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

import Translate from '@/i18n/Translate.vue';

export default defineComponent({
  name: 'LocaleInput',
  components: {
    Translate,
  },
  props: {
    modelValue: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    i18nLabel: {
      type: String,
      required: true,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      modelValueData: {},
    };
  },
  computed: {
    ...mapGetters({
      locales: 'locale/getAvailableLocales',
      getModalError: 'modal/getModalError',
    }),
  },
  created() {
    const model = this.modelValue;
    const newModel = {};

    if ('locale' in model) {
      newModel[model.locale] = model.value;
      Object.assign(this.modelValueData, newModel);
    } else {
      Object.assign(this.modelValueData, model);
    }
  },
  updated() {
    this.$emit('update:modelValue', this.modelValueData);
  },
});
