
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ChangePassword from '@/components/forms/ChangePassword.vue';
import ModalWrapper from '@/components/globals/ModalWrapper.vue';
import PortalFolder from '@/components/PortalFolder.vue';
import AdminEntry from '@/components/admin/Entry.vue';
import AdminFolder from '@/components/admin/Folder.vue';
import AdminExistingEntry from '@/components/admin/ExistingEntry.vue';
import AdminCategory from '@/components/admin/Category.vue';
import AdminExistingCategory from '@/components/admin/ExistingCategory.vue';
import TileAddModal from '@/components/admin/TileAddModal.vue';
import CategoryAddModal from '@/components/admin/CategoryAddModal.vue';
import ChooseTabs from '@/components/ChooseTabs.vue';
import LoadingOverlay from '@/components/globals/LoadingOverlay.vue';

export default defineComponent({
  name: 'PortalModal',
  components: {
  // Register and import all possible modal components here
  // Otherwise they will not be displyed correctly
  // (Maybe change PortalModal to not use the component tag anymore?)
    ChangePassword,
    ModalWrapper,
    PortalFolder,
    AdminEntry,
    AdminFolder,
    AdminExistingEntry,
    AdminCategory,
    AdminExistingCategory,
    ChooseTabs,
    LoadingOverlay,
    TileAddModal,
    CategoryAddModal,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      modalState: 'modal/getModalState',
      modalComponent: 'modal/getModalComponent',
      modalProps: 'modal/getModalProps',
      modalStubborn: 'modal/getModalStubborn',
    }),
  },
  methods: {
    closeModal(): void {
      if (!this.modalStubborn) {
        this.$store.dispatch('modal/hideAndClearModal');
      }
    },
  },
});
