
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import IconButton from '@/components/globals/IconButton.vue';
import ModalWrapper from '@/components/globals/ModalWrapper.vue';
import PortalBackground from '@/components/PortalBackground.vue';
import PortalCategory from 'components/PortalCategory.vue';
import PortalHeader from '@/components/PortalHeader.vue';
import PortalIframe from 'components/PortalIframe.vue';
import PortalModal from 'components/PortalModal.vue';
import PortalSidebar from '@/components/PortalSidebar.vue';
import PortalToolTip from 'components/PortalToolTip.vue';

import notificationMixin from '@/mixins/notificationMixin.vue';
import Translate from '@/i18n/Translate.vue';

import { Category } from '@/store/modules/portalData/portalData.models';
import createCategories from '@/jsHelper/createCategories';

export default defineComponent({
  name: 'Portal',
  components: {
    IconButton,
    ModalWrapper,
    PortalBackground,
    PortalCategory,
    PortalHeader,
    PortalIframe,
    PortalModal,
    PortalSidebar,
    PortalToolTip,
    Translate,
  },
  mixins: [notificationMixin],
  computed: {
    ...mapGetters({
      portalContent: 'portalData/portalContent',
      portalEntries: 'portalData/portalEntries',
      portalFolders: 'portalData/portalFolders',
      portalCategories: 'portalData/portalCategories',
      portalDefaultLinkTarget: 'portalData/portalDefaultLinkTarget',
      tabs: 'tabs/allTabs',
      activeTabIndex: 'tabs/activeTabIndex',
      editMode: 'portalData/editMode',
      tooltip: 'tooltip/tooltip',
      hasEmptySearchResults: 'search/hasEmptySearchResults',
    }),
    categories(): Category[] {
      return createCategories(this.portalContent, this.portalCategories, this.portalEntries, this.portalFolders, this.portalDefaultLinkTarget, this.editMode);
    },
    setModalContent() {
      let ret = '';
      if (this.currentModal === 'editEntry') {
        ret = this.tileObject;
      }
      return ret;
    },
  },
  methods: {
    addCategory() {
      this.$store.dispatch('modal/setAndShowModal', {
        name: 'CategoryAddModal',
      });
    },
  },
});
