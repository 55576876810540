
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FlyoutWrapper',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
});
