<!--
 * Copyright 2021 Univention GmbH
 *
 * https://www.univention.de/
 *
 * All rights reserved.
 *
 * The source code of this program is made available
 * under the terms of the GNU Affero General Public License version 3
 * (GNU AGPL V3) as published by the Free Software Foundation.
 *
 * Binary versions of this program provided by Univention to you as
 * well as other copyrighted, protected or trademarked materials like
 * Logos, graphics, fonts, specific documentations and configurations,
 * cryptographic keys etc. are subject to a license agreement between
 * you and Univention and not subject to the GNU AGPL V3.
 *
 * In the case you use this program under the terms of the GNU AGPL V3,
 * the program is provided in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public
 * License with the Debian GNU/Linux or Univention distribution in file
 * /usr/share/common-licenses/AGPL-3; if not, see
 * <https://www.gnu.org/licenses/>.
-->
<script>
import { store } from '@/store';
import { mapGetters } from 'vuex';

const notificationMixin = {
  computed: {
    ...mapGetters({
      bubbleState: 'notificationBubble/bubbleState',
      bubbleStateStandalone: 'notificationBubble/bubbleStateStandalone',
      bubbleStateNewBubble: 'notificationBubble/bubbleStateNewBubble',
      bubbleContent: 'notificationBubble/bubbleContent',
      bubbleContentNewNotification: 'notificationBubble/bubbleContentNewNotification',
      getActiveButton: 'navigation/getActiveButton',
    }),
  },
  methods: {
    // dismissBubble(): void {
    dismissBubble() {
      // store modal state
      store.dispatch('notificationBubble/setHideBubble');
      store.dispatch('notificationBubble/showEmbedded');
    },
    // dismissNotification(token: string): void {
    dismissNotification(token) {
      // remove selected bubble content
      store.dispatch('notificationBubble/deleteSingleNotification', token);
      if (token === this.getActiveButton) {
        if (document.getElementById('loginButton')) {
          document.getElementById('loginButton').focus();
        }
      }
    },
    // showNewNotification(content: WeightedNotification): void {
    showNewNotification(content) {
      // for new notifications only
      store.dispatch('notificationBubble/addContent', content);
      store.dispatch('notificationBubble/setShowNewBubble', content);
      setTimeout(() => {
        store.dispatch('notificationBubble/setHideNewBubble');
      }, 4000);
    },
  },
};

export default notificationMixin;
</script>
