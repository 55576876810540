
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { udmPut } from '@/jsHelper/umc';
import Translate from '@/i18n/Translate.vue';
import PortalIcon from '@/components/globals/PortalIcon.vue';
import ImageUpload from '@/components/widgets/ImageUpload.vue';
import LocaleInput from '@/components/widgets/LocaleInput.vue';

interface EditModeSideNavigationData {
  portalLogoData: string,
  portalNameData: Record<string, string>,
  portalBackgroundData: string,
  portalShowUmcData: boolean,
}

export default defineComponent({
  name: 'EditModeSideNavigation',
  components: {
    Translate,
    PortalIcon,
    ImageUpload,
    LocaleInput,
  },
  data(): EditModeSideNavigationData {
    return {
      portalLogoData: '',
      portalNameData: {},
      portalBackgroundData: '',
      portalShowUmcData: false,
    };
  },
  computed: {
    ...mapGetters({
      portalDn: 'portalData/getPortalDn',
      portalName: 'portalData/portalName',
      portalLogo: 'portalData/portalLogo',
      portalBackground: 'portalData/portalBackground',
      portalShowUmc: 'portalData/portalShowUmc',
    }),
  },
  updated() {
    this.update();
  },
  created() {
    // get initial logo data
    this.portalLogoData = this.portalLogo || '';
    this.portalNameData = this.portalName;
    this.portalBackgroundData = this.portalBackground || '';
    this.portalShowUmcData = this.portalShowUmc;
  },
  methods: {
    update() {
      this.$store.dispatch('portalData/setPortalName', this.portalNameData);
      this.$store.dispatch('portalData/setPortalLogo', this.portalLogoData);
      this.$store.dispatch('portalData/setPortalBackground', this.portalBackgroundData);
    },
    async saveChanges() {
      let logo: string | null = null;
      if (this.portalLogoData.startsWith('data:')) {
        logo = this.portalLogoData.split(',')[1];
      } else if (this.portalLogoData === '') {
        logo = '';
      }
      let background: string | null = null;
      if (this.portalBackgroundData.startsWith('data:')) {
        background = this.portalBackgroundData.split(',')[1];
      } else if (this.portalBackgroundData === '') {
        background = '';
      }
      const displayName = Object.entries(this.portalNameData);
      const showUmc = this.portalShowUmcData;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const attrs: any = { displayName, showUmc };
      if (logo !== null) {
        attrs.logo = logo;
      }
      if (background !== null) {
        attrs.background = background;
      }
      try {
        this.$store.dispatch('activateLoadingState');
        await udmPut(this.portalDn, attrs);
        await this.$store.dispatch('portalData/waitForChange', {
          retries: 10,
          adminMode: false,
        });
        this.$store.dispatch('portalData/setEditMode', false);
        this.$store.dispatch('navigation/setActiveButton', '');
      } catch (error) {
        this.$store.dispatch('notificationBubble/addErrorNotification', {
          bubbleTitle: 'Update failed',
          bubbleDescription: `'Saving the portal failed: ${error}'`,
        });
      }
      this.$store.dispatch('deactivateLoadingState');
    },
  },
});
