
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import FlyoutWrapper from '@/components/navigation/FlyoutWrapper.vue';
import ModalWrapper from '@/components/globals/ModalWrapper.vue';
import NotificationBubble from '@/components/globals/NotificationBubble.vue';
import NotificationBubbleSlot from '@/components/globals/NotificationBubbleSlot.vue';
import SideNavigation from '@/components/navigation/SideNavigation.vue';
import EditModeSideNavigation from '@/components/navigation/EditModeSideNavigation.vue';

import Translate from '@/i18n/Translate.vue';

export default defineComponent({
  name: 'PortalSidebar',
  components: {
    FlyoutWrapper,
    ModalWrapper,
    NotificationBubble,
    NotificationBubbleSlot,
    SideNavigation,
    EditModeSideNavigation,
    Translate,
  },
  computed: {
    ...mapGetters({
      portalName: 'portalData/portalName',
      activeButton: 'navigation/getActiveButton',
      activeTabIndex: 'tabs/activeTabIndex',
      tabs: 'tabs/allTabs',
    }),
    activeNotificationButton(): boolean {
      return this.activeButton === 'bell';
    },
    activeMenuButton(): boolean {
      return this.activeButton === 'menu';
    },
    activeEditModeButton(): boolean {
      return this.activeButton === 'settings';
    },
  },
  methods: {
    closeSidebar(): void {
      this.$store.dispatch('navigation/setActiveButton', '');
    },
  },
});
