
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'PortalBackground',
  computed: {
    ...mapGetters({ portal: 'portalData/getPortal' }),
    backgroundImageStyle(): string {
      if (!this.portal.portal.background) {
        return 'background-image: none';
      }
      return `background-image: url('${this.portal.portal.background}`;
    },
  },
});
