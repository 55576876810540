
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ModalDialog from '@/components/ModalDialog.vue';
import ModalWrapper from '@/components/globals/ModalWrapper.vue';
import IconButton from '@/components/globals/IconButton.vue';

export default defineComponent({
  name: 'ChooseTabs',
  components: {
    ModalDialog,
    ModalWrapper,
    IconButton,
  },
  computed: {
    ...mapGetters({
      tabs: 'tabs/allTabs',
    }),
    ariaLabelChooseTab(tabLabel: string): string {
      return `${tabLabel} ${this.$translateLabel('SELECT_TAB')}`;
    },
    ariaLabelCloseTab(tabLabel: string): string {
      return `${tabLabel} ${this.$translateLabel('CLOSE_TAB')}`;
    },
  },
  methods: {
    closeTab(idx: number) {
      this.$store.dispatch('tabs/deleteTab', idx + 1);
      if (this.tabs.length === 0) {
        this.$store.dispatch('navigation/setActiveButton', '');
      }
    },
    gotoTab(idx: number) {
      this.$store.dispatch('tabs/setActiveTab', idx + 1);
      this.cancel();
    },
    cancel() {
      this.$store.dispatch('navigation/setActiveButton', '');
    },
  },
});
