
import { defineComponent } from 'vue';

import HeaderButton from '@/components/navigation/HeaderButton.vue';
import notificationMixin from '@/mixins/notificationMixin.vue';

import { catalog } from '@/i18n/translations';

export default defineComponent({
  name: 'NotificationBubbleSlot',
  components: { HeaderButton },
  mixins: [
    notificationMixin,
  ],
  props: {
    bubbleContainer: {
      type: String,
      default: 'standalone',
    },
  },
  computed: {
    setBubbleContent() {
      let data;
      if (this.bubbleStateNewBubble && this.bubbleContainer === 'standalone') {
        data = this.bubbleContentNewNotification;
      } else if (this.bubbleContainer === 'embedded') {
        data = this.bubbleContent;
      }
      return data;
    },
    ariaLabelDismissButton() {
      return catalog.DISMISS_NOTIFICATION.translated.value;
    },
  },
  methods: {
    clickable(item): boolean {
      return !!item.onClick;
    },
    onClick(item): void {
      if (this.clickable(item)) {
        item.onClick();
      }
    },
  },
});

