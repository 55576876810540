
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PortalIframe',
  props: {
    link: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    (this.$refs.iframe as HTMLIFrameElement).contentWindow?.focus();
  },
  updated() {
    if (this.isActive) {
      (this.$refs.iframe as HTMLIFrameElement).contentWindow?.focus();
    }
  },
});
