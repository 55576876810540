
import { defineComponent } from 'vue';

import Translate from '@/i18n/Translate.vue';
import ModalDialog from '@/components/ModalDialog.vue';
import { setInvalidity } from '@/jsHelper/tools';

interface ChangePasswordData {
    oldPassword: string,
    newPassword: string,
    newPassword2: string,
}

export default defineComponent({
  name: 'ChangePassword',
  components: {
    ModalDialog,
    Translate,
  },
  data(): ChangePasswordData {
    return {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
    };
  },
  methods: {
    finish() {
      const oldPasswordSet = !!this.oldPassword;
      const newPasswordSet = !!this.newPassword;
      const newPasswordsMatch = this.newPassword === this.newPassword2;
      setInvalidity(this, 'oldPassword', !oldPasswordSet);
      setInvalidity(this, 'newPassword', !newPasswordSet);
      setInvalidity(this, 'newPassword2', !newPasswordsMatch);
      const everythingIsCorrect = oldPasswordSet && newPasswordSet && newPasswordsMatch;
      if (!everythingIsCorrect) {
        return;
      }
      this.$store.dispatch('modal/resolve', {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      });
    },
    cancel() {
      this.$store.dispatch('modal/reject');
    },
  },
});
