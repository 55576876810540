
import { defineComponent } from 'vue';
import PortalIcon from '@/components/globals/PortalIcon.vue';

export default defineComponent({
  name: 'IconButton',
  components: { PortalIcon },
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
});
