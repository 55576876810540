
import { defineComponent, PropType } from 'vue';

import PortalIcon from '@/components/globals/PortalIcon.vue';
import TileClick from '@/mixins/TileClick.vue';

import { Locale } from '@/store/modules/locale/locale.models';

export default defineComponent({
  name: 'MenuItem',
  components: { PortalIcon },
  mixins: [
    TileClick,
  ],
  props: {
    title: {
      type: Object as PropType<Record<Locale, string>>,
      required: true,
    },
    subMenu: {
      type: Array,
      default: () => [],
    },
    isSubItem: {
      type: Boolean,
      default: false,
    },
    handlesAppSettings: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLink(): boolean {
      return this.link !== null && this.link !== '';
    },
  },
});
