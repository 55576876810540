
import { defineComponent } from 'vue';

import PortalIcon from '@/components/globals/PortalIcon.vue';
import Draggable from '@/mixins/Draggable.vue';

export default defineComponent({
  name: 'TileAdd',
  components: { PortalIcon },
  mixins: [
    Draggable,
  ],
  props: {
    superDn: {
      type: String,
      required: true,
    },
    forFolder: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showMenu(): void {
      this.$store.dispatch('modal/setAndShowModal', {
        name: 'TileAddModal',
        props: {
          superDn: this.superDn,
          forFolder: this.forFolder,
        },
      });
    },
  },
});
