
import { defineComponent, PropType } from 'vue';

import ModalDialog from '@/components/ModalDialog.vue';
import Translate from '@/i18n/Translate.vue';

export interface ValidatableData {
  getErrors: () => Record<string, string>,
}

export default defineComponent({
  name: 'EditWidget',
  components: {
    ModalDialog,
    Translate,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    canRemove: {
      type: Boolean,
      required: true,
    },
    model: {
      type: Object as PropType<ValidatableData>,
      required: true,
    },
  },
  emits: ['remove', 'save'],
  mounted() {
    this.$el.querySelector('input:enabled')?.focus();
  },
  methods: {
    cancel() {
      this.$store.dispatch('modal/hideAndClearModal');
    },
    submit() {
      const errors = this.model.getErrors();
      if (Object.keys(errors).length === 0) {
        this.$emit('save');
      } else {
        this.$el.querySelectorAll('input').forEach((input) => {
          if (input.name) {
            if (input.name in errors) {
              input.setAttribute('invalid', 'invalid');
            } else {
              input.removeAttribute('invalid');
            }
          }
        });
        const description = Object.values(errors)
          .map((err) => this.$translateLabel(err))
          .join('</li><li>');
        this.$store.dispatch('notificationBubble/addErrorNotification', {
          bubbleTitle: this.$translateLabel('ERROR_ON_VALIDATION'),
          bubbleDescription: `<ul><li>${description}</li></ul>`,
        });
      }
    },
  },
});
